export const WORDS = [
  'samba',
  'dance',
  'stage',
  'floor',
  'gemma',
  'harry',
  'shark',
  'salsa',
  'tango',
  'becca',
  'vogue',
  'coach',
  'latin',
  'waltz',
  'sleep',
  'dress',
  'music',
  'peter',
  'chris',
  'medal',
  'leeds',
  'craig',
  'katya',
]
